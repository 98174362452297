import React from 'react'
import Layout from '../components/layout'
import LinkedinMarketing from '../components/linkedin-marketing'
import Seo from '../components/seo'

const LinkedinMarketingPage = ({ location }) => (
  <Layout location={location}>
    <LinkedinMarketing />
  </Layout>
)

export const Head = ({ location }) => {
  const title = 'LinkedIn Marketing 2020: Automation, Strategy & Results'
  const description =
    'LinkedIn Marketing is the hottest direct marketing strategy in 2020 providing the biggest results. Contact a LinkedIn Marketing Professional at Code Staff.'

    return <Seo type="page" title={title} description={description} />
}

export default LinkedinMarketingPage
