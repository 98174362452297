import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/main.css'
import '../css/main.scss'
import FeaturedArticles from './featured-articles'
import TopTrendingSkills from './top-trending-skills'
import ArticleAside from './article-aside'
import HireAndApply from './hire-and-apply'
import { StaticImage } from "gatsby-plugin-image"

const Main = () => (
    <main>
        <section className="banner banner--img">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="u-color-black-4 u-weight-bld">LINKEDIN MARKETING</p>
                        <h1 className="h1--sm u-color-secondary-alt-3 u-weight-smb u-font-main mt-2 mb-4">How To Effectively Use LinkedIn<br className="d-none d-lg-block" /><span className="d-lg-none">&nbsp;</span>For Sales And Marketing</h1>
                        <p className="p--xl">By <span className="u-color-secondary-alt-3 u-weight-smb"><a href='https://www.linkedin.com/in/devin-mallonee/'>Devin Mallonee</a></span>&nbsp;&nbsp;&nbsp;&nbsp;September 12, 2019&nbsp;&nbsp;&nbsp;&nbsp;249 shares&nbsp;&nbsp;&nbsp;&nbsp;15 Comments</p>
                        <StaticImage src='../images/banner-2.jpg' alt='Article banner' className='banner__img' />
                    </div>
                </div>
            </div>
        </section>
        <section className="article">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="article__inner">
                            <div className="article__main">
                                <div className="article__main-intro">
                                    <p className="p--xhg u-weight-lgt">LinkedIn is a social network with real professional applications. It is used by many professionals for the purposes of growing their professional connections, career development, business-related activities, and industry discussions with other like-minded professionals. It is a social network that gives companies a link to grow their business through the connections that they make with other companies and professionals.</p>
                                    <p>These are not the only uses for LinkedIn. It is a multifaceted resource for businesses and professionals.</p>
                                </div>
                                <div className="article__main-content">
                                    <div className="article__main-content-group">
                                        <h2 className="h2--md u-weight-bld u-color-black-7 mb-4">LinkedIn Can Be So Much More</h2>
                                        <p>Aside from just being a social network, professionals and companies are using LinkedIn to do so much more than creating a network. They are using for not only increasing their number of connections but improving the quality of those connections. They are improving brand awareness, establishing partnerships, and generating leads.</p>
                                        <p>LinkedIn can be a real source of marketing and sales for many brands and companies. It is just a different type of social network that requires a different marketing approach. </p>
                                    </div>
                                    <div className="article__main-content-group">
                                        <h2 className="h2--md u-weight-bld u-color-black-7 mb-4">Using LinkedIn For Sales And Marketing</h2>
                                        <p>With social media platforms like Facebook and Instagram, people expect to see companies pushing their products and services. They are expecting to see the hard sell, but this type of activity is looked down upon on LinkedIn. Sales and marketing need to be approached in a different way.</p>
                                    </div>
                                    <div className="article__main-content-group">
                                        <h2 className="h2--md u-weight-bld u-color-black-7 mb-4">Transforming Connections into Relationships</h2>
                                        <p>The connections that are made on LinkedIn are professional to professional, business to business, and brand to brand. Whether the connection is with other owners, partners, referral partners, or prospects, these connections must be nurtured. Once the connection is made, they must be turned into a business relationship in order for both sides to benefit from the connection.</p>
                                    </div>
                                    <div className="article__main-content-group">
                                        <h2 className="h2--md u-weight-bld u-color-black-7 mb-4">Strategic Targeting</h2>
                                        <p>Strategic targeting for marketing campaigns can be done on LinkedIn in a way that is not possible on other social media sites. Companies can identify the country (or countries), job role, industry, and company size of those who are within the targeted demographic and they can tailor their marketing campaigns and correspondence for that specific demographic. Companies will know that their campaigns are reaching those that are most likely to purchase their products or services. </p>
                                        <p>This strategic targeting can be used in all forms of marketing including an email list, sponsored content, and all other campaigns. This is the most valuable thing that LinkedIn has to offer a marketing campaign because it is guaranteed to get in front of the right person/people within that specified demographic of professionals.</p>
                                        <p>This strategic targeting will also help to get your name and company from getting lost in a sea of other irrelevant companies that have nothing to do with you or what you have to offer. </p>
                                    </div>
                                    <div className="article__main-content-group">
                                        <h2 className="h2--md u-weight-bld u-color-black-7 mb-4">Your High-Quality Content Can Go Viral</h2>
                                        <p>When posting content, you want to make sure that your content is doing two things;
1. It is helping people to solve a problem or do their jobs better
2. It is also establishing your company or brand as a leader in solving that particular problem</p>
                                        <p>Once you have made sure that your content is fulfilling both of those things and you are getting it in front of the eyes of that strategically targeted demographic, it can pick up some momentum. Once it has picked up momentum, LinkedIn will spotlight this content and get it in front of tens of thousands of interested professionals. They do this through their categories, and it is a way to get added eyes on your content.</p>
                                    </div>
                                    <div className="article__main-content-group">
                                        <h2 className="h2--md u-weight-bld u-color-black-7 mb-4">LinkedIn Groups</h2>
                                        <p>By joining LinkedIn groups that are related to what it is that you, your company, or your brand has to offer, you can grow the connections that you can make exponentially. When you or your company join a group, you can message others in that group, conversate with them, and have an ear what is going on within that particular industry with other professionals.
</p>
                                        <p>Groups give you a way to make more connections within an industry in a flash. These groups are a great way for other potential partners or customers to get to know you and your business and to build a relationship that can really result in more leads.</p>
                                        <p>Then you get them to join your company’s group. You will definitely want to have a group established for your business as well. It will give those leads that you have generated from other groups or connections a place to gather to learn more about you and what you have to offer them.</p>
                                        <p>Remember, LinkedIn is about connections and turning them into relationships. The trust that is established within that relationship is going to help you to get your company out there and get your marketing campaigns in front of those that need to see them. LinkedIn has real potential with marketing and sales but it has to be approached through the lens of creating a relationship with those connections that are going to help you to reach your goals and get your company to where you want it to be.</p>
                                    </div>
                                </div>
                            </div>
                            <ArticleAside />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FeaturedArticles />
        <HireAndApply />
        <TopTrendingSkills />
    </main>
)
export default Main
